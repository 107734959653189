import React, { useRef, useState } from "react";
import Helmet from "react-helmet";
import TicketShop from "components/ticket-shop";

import { Page } from "components/Page";
import { MotionImage } from "components/MotionImage";

const IndexPage = (p) => {
  return (
    <Page overlayHeader>
      <Helmet
        htmlAttributes={{
          lang: "en-GB",
        }}
        title={"The Bhangra Club"}
        meta={[
          {
            name: `description`,
            content: "The Bhangra Club",
          },
          {
            property: `og:url`,
            content: `https://thebhangraclub.com`,
          },
          {
            property: `og:title`,
            content: "The Bhangra Club",
          },
          {
            property: `og:description`,
            content: "",
          },
          {
            property: `og:image`,
            content: ``,
          },
        ]}
      />
      <div className="relative">
        <MotionImage
          className="w-full object-contain mx-auto mt-12"
          style={{ maxWidth: 520, aspectRatio: 1 }}
          src="/logo.png"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        />
        <div className="container mx-auto text-center">
          <img
            className="mx-auto block mt-6 mb-6"
            src="/club.svg"
            alt="Elite members club"
          />
          <p>Connecting Bhangra fans through exclusive events and gatherings</p>
        </div>
      </div>
      <section className="mt-16">
        <div className="container mx-auto">
          <div className="mt-32 md:mt-32">
            <TicketShop
              data-next-event-heading=""
              scriptString={`<script src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js?salesChannel=whitelabel-website" data-fixr-shop-id="2edc820b-31d3-4083-9e4a-342a86fa3e63" data-fixr-theme="dark"></script>`}
            />
          </div>
        </div>
      </section>
    </Page>
  );
};

export default IndexPage;
